<template>
  <div class="table__user"
  >
    <div class="table__user-left">
      <UiTooltip
          :text="`Группы: ${groupsToString( row.groupNames)}`"
          background="#DBEBFF"
          color="#20579A"
          buttom
      >
        <template #activator>
          <div class="user__info">
            <div class="user__info-name">{{row.name}}</div>
            <div class="user__info-role">{{groupsReduce(row.groupNames)}}</div>
          </div>
        </template>
      </UiTooltip>
    </div>
    <div class="table__user-right" >
<!--      <div class="icon" v-if="!showPopup">-->
<!--        <UiIcon-->
<!--            value="grey_more"-->
<!--            width="4px"-->
<!--            height="10px"-->
<!--        />-->
<!--      </div>-->
      <div  class="commands">
      </div>
    </div>
  </div>

</template>

<script setup>
import {defineProps, defineEmits, ref, computed } from 'vue'
import UiIcon from "./ui/icon.vue"
import UiPopup from './ui/popup.vue'
import UiTooltip from './ui/tooltip.vue'
import ChangeRoles from "@/components/companyControlsCenter/components/AccessSettings/changeRoles.vue";

const props = defineProps({
  row: {
    type: Object,
    default: () => {}
  },
  rowObject: {
    type: Object,
    default: () => {}
  },
  isShowPopup: {
    type: Boolean,
    default: false
  },
  isPopup: {
    type: Boolean,
    default: false
  },
  roleName: {
    type: String,
    default: ''
  }
})

const emit = defineEmits(['deleteUser'])
const showPopup = ref(props.isShowPopup)
const popup = ref(props.isPopup)
const change = ref(false)

const positionStyle = computed(() => {
  if(props.roleName === 'Группы доступа') {
    return '386px'
  } else if (props.roleName === 'Пользователи (Группы)') {
    return '360px'
  }
})
const positionRoleItems = computed(() => {
  if(props.roleName === 'Группы доступа') {
    return '176px'
  } else if (props.roleName === 'Пользователи (Группы)' ) {
    return '203px'
  }
})

const openPopup = () => {
  closeRoles()
  showPopup.value = true
}
const closePopup = () => {
  showPopup.value = false
  popup.value = false
}
const closeRoles = () => {
  change.value = false
}

const groupsToString = (groups) => {
  return groups.join(', ')
}

const groupsReduce = (groups) => {
  if(groups[0] && groups[1]) {
    return `${groups[0]}, ${groups[1]}`
  }else if(!groups[1]){
    return `${groups[0]}`
  }
}

</script>

<style scoped lang="scss">
.table__user {
  display: flex;
  justify-content: space-between;
  padding: 2px 4px 1px 8px;
  height: 100%;
  max-width: 250px;
  &:hover {
    background: #F6F7FA;
  }

  &-left {
    //display: flex;
    //column-gap: 4px;
    //align-items: center;

    .user__info {
      text-align: left;

      &-name {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
        color: #292929;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 210px;
      }

      &-role {
        font-size: 9px;
        font-style: normal;
        font-weight: 600;
        line-height: 80%;
        letter-spacing: 0.5px;
        text-transform: uppercase;
        color: #A6AEB8;
        max-width: 200px;
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

      }
    }
  }

  &-right {
    position: relative;

    .icon {
      border-radius: 100%;
      width: 20px;
      height: 20px;
    }

    .commands {
      position: absolute;
      top: 3px;
      right: 0;

      :deep(.popup) {

        .popup__activator {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 20px;
          height: 20px;
        }

        .popup__body {
          border: 1px solid #DCE1E7;
          left: v-bind(positionStyle) !important;

          .button__body {
            padding: 9px 0;
            display: flex;
            flex-direction: column;
            row-gap: 7px;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 19px;
            color: #292929;
            position: relative;
            height: max-content;


            &-change {

              display: flex;
              align-items: center;
              justify-content: space-between;
              column-gap: 16px;

              .transform{
                transform: rotate(270deg);
                color: #FFFFFF;
              }
            }

            .item {
              white-space: nowrap;
              text-align: left;
              padding: 0 12px;
            }

            &-delete {
              color: #C93131;
            }
          }

          .change__roles {
            top: 1px;
            position: absolute;
            left: v-bind(positionRoleItems);

          }
        }
      }
    }
  }
}
</style>
