<template>
  <div class="popup" tabindex="1" >
    <div @click.stop="showPopup" class="popup__activator" ref="activator">
      <slot name="activator"></slot>
    </div>
    <Transition name="slide-fade" appear>
      <div
        @click.stop="$emit('input', false)"
        v-show="value"
        :class="['popup__body', { 'popup__body-right': right }]"
        :style="{ top, left }"
      >
        <slot name="body"></slot>
      </div>
    </Transition>
  </div>
</template>

<script setup lang="ts">
import { defineEmits, defineProps, ref } from 'vue';

const left = ref('100px');
const top = ref('100');
const activator = ref(null);

const props = defineProps({
  text: {
    type: String,
    default: '',
  },
  background: {
    type: String,
    default: '',
  },
  right: {
    type: Boolean,
    default: false,
  },
  color: {
    type: String,
    default: '',
  },
  value: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['input']);

const showPopup = () => {
  let itemTop = activator.value.getBoundingClientRect().top;
  setTimeout(() => {
    const clientHeight = document.querySelector('.popup__body').clientHeight;
    itemTop += clientHeight;
    if (itemTop > window.innerHeight - 15) {
      top.value = activator.value.getBoundingClientRect().top - clientHeight + 'px';
    } else {
      top.value = activator.value.getBoundingClientRect().top + 18 + 'px';
    }
    emit('input', !props.value);
  }, 0);
  left.value = activator.value.getBoundingClientRect().left - 165 + 'px';
};
</script>

<style scoped lang="scss">
.popup {
  position: relative;
  transition: 0.3s all;
  outline: none;

  &__activator {
    padding: 10px 4px;
    box-sizing: border-box;
    background: #edeff3;
    border-radius: 100%;
    cursor: pointer;
  }

  &__body {
    position: fixed;
    box-sizing: border-box;
    transition: 0.3s;
    background: #edeff3;
    border: 1px solid #dce1e7;
    box-shadow: 0 2px 2px rgba(255, 255, 255, 0.1),
      0 4px 16px rgba(0, 0, 0, 0.06);
    border-radius: 12px;
    display: flex;
    align-items: flex-start;
    z-index: 1300;
    margin-top: 2px;
    //top: v-bind(top);
    //left: v-bind(left);
    //overflow: hidden;
    left: 377px;
    top: 279px;

    &-right {
      left: auto;
      right: 0;
      margin-top: 10px;
    }
  }

  .slide-fade-enter-active {
    transition: all 0.3s ease-out;
  }

  .slide-fade-leave-active {
    transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
  }

  .slide-fade-enter-from,
  .slide-fade-leave-to {
    transform: translateY(-5px);
    opacity: 0;
    z-index: 0;
  }
}
</style>
