<template>
  <div class="groups">
    <div class="groups__header">
      <el-input
          class="groups__header-search"
          :placeholder="$t('search')"
          v-model="searchGroup"
      />
    </div>
    <div class="groups__block">
      <div class="groups__block-item"
           :class="{'users__block-none': user.isActive}"
           v-for="user in allGroups"
           :key="user.id"
           @click="emit('addGroupToUser', user)"
      >
        <div class="groups__block-item-name">{{ user.name }}</div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, defineProps, computed, defineEmits } from 'vue'

const props = defineProps({
  groups: {
    type: Array,
    default: () => []
  },
  buttonName: {
    type: String,
    default: ''
  },
  addUsersType: {
    type: String,
    default: ''
  },
  available: {
    type: Array,
    default: () => []
  }
})

const emit = defineEmits(['deleteItem', 'addGroupToUser'])

const searchGroup = ref('')
const allGroups = computed(() => {
  const ids = props.available.map(el=>el.group.id)
  let data = props.groups.filter(item =>!ids.includes(item.id))
  if (searchGroup.value) {
    return data.filter((item) => {
      return item && item.name
          ? item.name
              .toLowerCase()
              .includes(searchGroup.value.toLowerCase())
          : null
    })
  }

  return data
})
</script>

<style scoped lang="scss">
.groups {
  overflow: hidden;
  width: 266px;
  height: max-content;
  max-height: 460px;
  background: #EDEFF3;
  border-radius: 12px;
  border: 1px solid #DCE1E7;

  &__header {
    padding: 8px;
    border-bottom: 1px solid #EDEFF3;

    &-search {
      width: 250px;
      height: 36px;
      border-radius: 10px;
      :deep(.el-input__inner) {
        background: #ffffff;
      }
    }

  }

  &__block {
    max-height: 400px;
    overflow-y: scroll;

    &-item {
      padding: 5px 12px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #EDEFF3;
      cursor: pointer;

      &-name {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
        color: #171717;
      }

      &:hover {
        background: #ffffff;
        color: #5C9DEB;
      }



      &-btn {
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
        color: #A6AEB8;
        border: none;
      }
    }

    &-none {
      pointer-events: none;
    }
  }
}

:deep(.el-input__inner) {
  width: 250px !important;
  height: 36px !important;
}

</style>
