<template>
  <div class="loader-line">
    <div class="ellipsis"><div></div><div></div><div></div><div></div></div>
  </div>
</template>

<script>
export default {
  name: "loaderLine"
}
</script>

<style scoped lang="scss">
.loader-line {
  display: flex;
  align-items: center;
  justify-content: center;
  .ellipsis {
    display: inline-block;
    position: relative;
    height: 20px;
    min-width: 100%;
  }
  .ellipsis div {
    position: absolute;
    top: 0;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: gray;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  .ellipsis div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
  }
  .ellipsis div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .ellipsis div:nth-child(3) {
    left: 22px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .ellipsis div:nth-child(4) {
    left: 46px;
    animation: lds-ellipsis3 0.6s infinite;
  }
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(14px, 0);
  }
}
</style>