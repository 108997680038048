import { render, staticRenderFns } from "./samplesSettings.vue?vue&type=template&id=e2214866&scoped=true"
import script from "./samplesSettings.vue?vue&type=script&setup=true&lang=js"
export * from "./samplesSettings.vue?vue&type=script&setup=true&lang=js"
import style0 from "./samplesSettings.vue?vue&type=style&index=0&id=e2214866&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e2214866",
  null
  
)

export default component.exports