<template>
  <div class="roles">
    <div class="roles__items" v-for="item in items" :key="item.id">
      <div class="roles__items-item" @click="changeUserRole(item)">{{ item.value }}</div>
    </div>
  </div>
</template>

<script setup>
import { defineProps } from 'vue'

const props = defineProps({
  items: {
    type: Array,
    default: () => []
  },
  row:{
    default:()=>{}
  }
})
const emit = defineEmits(['change'])

const changeUserRole = (item) =>{
  emit('change', item, props.row )
}

</script>

<style scoped lang="scss">
.roles {
  border: 1px solid #DCE1E7;
  background: #EDEFF3;
  border-radius: 12px;
  padding: 6px 0;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  color: #292929;
  width: 170px;
  text-align: start;
  height: 165px;
  overflow-y: auto;
  overflow-x: hidden;

  &__items {
    padding: 3px 12px;
    white-space: nowrap;

    &-item {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }

}
</style>
