<template>
  <div class="modal__delete">
  <UiDialog :modelValue="true" width="100%" >
    <div class="delete__role">
      <div class="delete__role-header">
        <div class="header__left">
          <div class="header__left-title">
            {{ $t('access.modal.delete.role.title') }}
          </div>
          <div class="header__left-info">
            {{ $t('access.modal.delete.role') }} {{deleteUser.value}}
          </div>
        </div>
        <div class="header__right-icon" @click="emit('closeModal', false)">
          <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 1.20857L10.7914 0L6 4.79143L1.20857 0L0 1.20857L4.79143 6L0 10.7914L1.20857 12L6 7.20857L10.7914 12L12 10.7914L7.20857 6L12 1.20857Z" fill="#292929"/>
          </svg>
        </div>
      </div>
      <div class="delete__role-body" v-if="deleteItem">
        <div class="w-full relative">
          <span class="object-sensors__details__label input-label">
            {{ $t('access.modal.delete.role.change') }}
          </span>
            <el-select
                v-model="selectedRole"
            >
              <el-option
                  :options="selectItems"
                  v-for="item in selectItems"
                  :key="item.id"
                  :value="item"
                  :label="item.value"
              />
            </el-select>
        </div>
        <div class="delete__role-info">
          <div class="info__img">
            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M11 0.999999C5.47715 0.999999 1 5.47715 1 11C1 16.5228 5.47715 21 11 21C16.5228 21 21 16.5228 21 11C21 5.47715 16.5228 1 11 0.999999Z" stroke="#EA7644" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M11 7L11 11" stroke="#EA7644" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M11 15L10.99 15" stroke="#EA7644" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </div>
          <div class="info__text">
            <div class="info__text-top">
              {{ $t('access.modal.delete.role.description.first') }}
              «{{deleteUser.value.slice(0,50)+'...'}}»
              {{ $t('access.modal.delete.role.description.second') }}
            </div>
            <div class="info__text-bottom">
              <p>
                {{ $t('access.modal.delete.role.description.third') }}
                «{{deleteUser.value.slice(0,50)+'...'}}»:
              </p>
              <ul v-for="(item, idx) in changeRoleOfUsers" :key="idx">
                <li> - {{item.name}}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="delete__role-footer" v-if="finallyDeleteItem">
        <el-button class="delete__role-footer__cancel" @click="cancelDelete">
          {{ $t('access.modal.delete.role.btn.cancel') }}
        </el-button>
        <el-button class="delete__role-footer__delete" @click="deleteRole">
          {{ $t('access.modal.delete.role.btn.delete') }}
        </el-button>
      </div>
    </div>
  </UiDialog>
  </div>

</template>

<script setup>
import UiDialog from "@/components/companyControlsCenter/components/AccessSettings/ui/dialog.vue"

import {ref, toRef, defineEmits, defineProps, onMounted,computed} from "vue";
import {useStore} from "@/store/store";

const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false
  },
  items: {
    type: Array,
    default: () => []
  },
  deleteUser: {
    type: Object,
    default: () => {}
  }
})

const emit = defineEmits(['update:modelValue', 'closeModal'])

const showDialog = toRef(props, 'modelValue')
const selectedRole = ref({})
const deleteItem = ref(true)
const finallyDeleteItem = ref(true)
const Store = useStore()

const changeRoleOfUsers = Store.getters['users/getAllUsers'].filter(val=>val.company_user).filter(user=>user.role.id===props.deleteUser.id)

const deleteRole = () => {
  if(deleteItem.value) {
    deleteItem.value = false
  }
  if(!deleteItem.value) {
      emit('deleteItem' , selectedRole.value)
      emit('closeModal', false)
      deleteItem.value = true
      finallyDeleteItem.value = false
  }
}

const cancelDelete = () => {
  if(deleteItem.value === true) {
    emit('closeModal', false)
  }
  else if(!deleteItem.value) {
    deleteItem.value = true
  }
}
const selectItems=computed(()=>{
  return props.items.filter(item=>item.value!==props.deleteUser.value)
})

onMounted(() => {
  selectedRole.value = props.items.find(val => val.key === "READER")
})
</script>

<style scoped lang="scss">
.modal__delete{
  z-index: 10000;
}
.delete__role {
  z-index: 100000000000;
  width: 400px;
  margin: 60px auto 0;
  background: #FFFFFF;
  border-radius: 12px;

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 18px 20px 16px 20px;
    border-bottom: 1px solid #CDD4DD;


    .header__left {
      &-title {
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%;
        color: #292929;
      }
      &-info{
        max-width: 370px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .header__right-icon {
      border-radius: 100%;
      background: #EDEFF3;
      width: 28px;
      height: 28px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }

  &-body {
    padding: 20px;
    border-bottom: 1px solid #CDD4DD;

    .delete__role-info {
      background: #FFE8DB;
      border-radius: 12px;
      display: flex;
      column-gap: 8px;
      padding: 10px 12px;
      color: #CA5F32;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 14px;
      margin-top: 12px;

      .info__text {
        display: flex;
        flex-direction: column;
        row-gap: 20px;
        &-top,&-bottom{
          max-width: 310px;
        }
      }
    }
  }

  &-footer {
    display: flex;
    column-gap: 12px;
    padding: 16px 20px;

    &__cancel {
      width: 174px;
      height: 44px;
      background: #DBEBFF;
      color: #3A7CCA;
      border-radius: 10px;
    }

    &__delete {
      width: 174px;
      height: 44px;
      background: #C93131;
      color: #ffffff;
      border-radius: 10px;
    }
  }
}
.asd {
  position: relative;
  background: grey;
}
::v-deep(.el-select) {
  .el-input__inner {
    //height: 48px !important;
    //padding: 21px 0 8px 12px !important;
    width: 360px;
  }
}
</style>


