<template>
  <div class="objects__access" @click="Store.commit('accessSettings/setAccessUsersInGroups',false)">
    <div class="objects__access-header">
      <div class="header__left">
        <span>
          {{ $t('access.settings.access.in.group') }}
        </span>
        <div class="header__left-icon">
          <UiIcon value="information-v2" height="16px" width="16px" />
        </div>
      </div>
      <div class="header__right">
        <div class="header__right-settings">
          <div class="objects__info" v-if="isSaveButtonActive">
            <UiIcon value="orange-info" width="22px" height="22px"/>
            <span>
              {{ $t('access.settings.warning.save') }}
            </span>
          </div>
          <el-button
              :class="['header__right-settings-add',{'active-btn': isSaveButtonActive }]"
              :disabled="!isSaveButtonActive"
              @click="handler"
          >
            {{ $t('save') }}
          </el-button>
        </div>
        <div class="header__right-img" @click="Store.commit('accessSettings/setIsAccessSettings', false)">
          <UiIcon value="allAccessClose" height="12px" width="12px"/>
        </div>
      </div>
    </div>
    <div class="objects__access-loader" v-if="Store.state.accessSettings.isLoading === true">
      <UiLoader/>
    </div>
    <div class="objects__access-body" v-if="Store.state.accessSettings.isLoading === false">
      <div class="objects__access-body-header">
        <div class="group__info">
          <div class="group__info-name">{{ objectInfo.name }}</div>
          <div class="group__info-count">({{ $t('access.settings.users.count') }} {{objectsDataProps.length}})</div>
        </div>
        <div class="group__search">
          <input
              class="input-inner"
              :placeholder="$t('search')"
              v-model="searchObject"
          />
          <UiIcon
              value="skif_search"
              height="20px"
              width="20px"
              class="group__search-icon"
          />
        </div>
        <div class="group__settings">
          <div class="group__settings-add" @click.stop="Store.commit('accessSettings/setAccessUsersInGroups',true)">
            <UiIcon
                value="add"
                width="16px"
                height="16px"
                color="#3A7CCA"
                class="mt-1"
            />
            <div class="add__name">
              {{ $t('access.settings.create.user') }}
            </div>
          </div>
          <div
              v-if="Store.getters['accessSettings/getAccessUsersInGroups']"
              class="users__items"
          >
            <AddUsers
                :included="rows"
                :users="getUsers"
                addUsersType="users"
                :buttonName="$t('access.settings.create.btn')"
                @addUser="addUser"
            />
          </div>
        </div>
      </div>
      <div
          class="objects__access-body-table"
          :key="tableKey"
      >
        <div class="table__content">
          <table>
            <thead>
            <tr class="table__row">
              <th class="first__item">-</th>
              <th v-for="column in columns" :key="column.id">
                <div class="table__content_item">{{ column.name }}</div>
              </th>
            </tr>
            </thead>
            <tbody v-if="!loading && isReadyColumn" :key="keyColumn">
              <tr v-for="row in rows" v-if="row" :key="row.id">
                <td>
                  <UserInfo
                      :isPopup="row.popup"
                      :isShowPopup="row.showPopup"
                      :row="row"
                      @deleteUser="removeUser"
                      @changeRole="changeRole"
                      roleName="Группы доступа"
                  />
                </td>
                <td
                    v-for="column in columns"
                    :key="column.id">
                  <input
                      type="checkbox"
                      @change.prevent="(e) => handleCheckboxChange(row.id, column.name, e.target.checked,row)"
                      :checked="isActionActive(row.actions, column.id)"
                      :class="{ active: isActionActive(row.actions, column.id) }"
                      :disabled="disabledActions(row, column)"
                  />
                </td>
              </tr>
            </tbody>
            <div class="loading" v-else>
              <Loader />
            </div>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {ref, defineProps, computed, onMounted, watchEffect, defineEmits, toRef, onUnmounted} from 'vue'
import UiIcon from './ui/icon.vue'
import AddUsers from '@/components/companyControlsCenter/components/AccessSettings/addUsers.vue'
import UserInfo from '@/components/companyControlsCenter/components/AccessSettings/userInfo.vue'
import Loader from '@/components/unitsNew/components/modalSensors/components/loader.vue'
import { useStore } from '@/store/store.js'
import UiLoader from "@/components/unitsNew/components/modalSensors/components/loader.vue";
import _,{debounce} from "lodash";

const Store = useStore()
const emit = defineEmits(['addUserToRow', 'updateRerenderKey','changeUserRole'])

const props = defineProps({
  objectInfo: {
    type: Object,
    default: () => {}
  },
  objects: {
    type: Array,
    default: () => []
  },
  groupId: {
    type: String,
    default: () => ''
  },
  savedUnits: {
    type: Object,
    default: () => {},
  }
})
let objectsDataProps = ref([]);
const usersData = ref([])
const isSaveButtonActive = ref(false)
const openSearch = ref(false)
const searchObject = ref('')
const loading = ref(false)
const isReadyColumn = ref(false)
const keyColumn = ref(0)
objectsDataProps = toRef(props, 'objects')

const getActionsItems = computed( () => {
  return  Store.getters['accessSettings/getAllActions']
})

const getUsers = computed(() => {
  return Store.getters['users/getAllUsers'].map(val => {
    val.isActive = false
    return val
  }).filter(val=>val.company_user)
})
const handler=_.debounce(async function() {
 await handleSave()
}, 1000, {
  loading: true,
  throttle: false,
  maxWait: 1500,
})

const columns = ref(
    getActionsItems.value.filter((c) => {
      if (c !== null) {
        return c._unit_action
      }
    })
)

const changeRole = (item,row)=>{
  emit('changeUserRole', item, row)
}

const  addUser = async (newUser) => {
  const exists = rows.value.some(item => item.id === newUser.id);
  if (!exists) {
    const newActions = columns.value.map(c=>{
        return {
          id:c.id,
          name:c.name,
          key:c.key
        }
      })
    // newUser.actions = newActions
    // newUser.available_actions_keys = ['update_unit', 'delete_unit', 'update_unit_imei', 'update_unit_name_icon', 'get_unit']
    objectsDataProps.value.push(newUser)
    openSearch.value=true
    usersData.value.push({
      actions: newActions,
      user: newUser,
    })
  }
  await handleSave()
}

const removeUser =async (e) => {
  usersData.value=usersData.value.filter(el=>el.user.id!==e.id)
  await handleSave()
}

const rows = computed(() => {
  if (searchObject.value) {
    return objectsDataProps.value.filter((item) => {
      return item
          ? item.name
              .toLowerCase()
              .includes(searchObject.value.toLowerCase())
          : null
    })
  }
  return objectsDataProps.value
})

const disabledActions = (row, column) => {
  const possibleActionsKeys = allRoles.value.filter(role=>role.id===row.role.id)[0].units_actions.map(action => action.key)
  if(possibleActionsKeys.includes(column.key)){
    return false
  }else{
    return true
  }
}

const isActionActive = (actions, columnId) => {
  if (!actions || actions.length === 0) {
    return false;
  }
  return actions.some(action => action.id === columnId);
}

const allUsers = computed(() => {
  return Store.getters['users/getAllUsers'].filter(val=>val.company_user)
})

const allRoles = computed(() => {
  return Store.getters['accessSettings/getAllRoles']
})

const tableKey = computed(() => {
  return `table-${props.groupId}`;
});

const handleCheckboxChange = async (userId, actionName, isChecked, row) => {
  const userIndex = usersData.value.findIndex(u => u.user.id === userId);
  const possibleActions = allRoles.value.filter(role=>role.id===row.role.id)[0].units_actions
  if (userIndex !== -1) {
    const actionIndex = usersData.value[userIndex].actions.findIndex(a => a.name === actionName);
    if (actionIndex !== -1) {
      usersData.value[userIndex].actions[actionIndex].isActive = isChecked;
    }else{
      const actionOnAdd = possibleActions.filter(action=>{
        if(action.name === actionName){
          return action
        }
      })[0]
      actionOnAdd.isActive=true
      usersData.value[userIndex].actions.push(actionOnAdd)
    }
  }
  isSaveButtonActive.value=true
}

const prepareDataForSaving = () => {
  const dataToSave = usersData.value.filter(user=>{
    if (user) return user
  })
  .map(user=>{
    if(user){
      return {
        user:user.user,
        actions:user.actions.filter(a=>{
          if(!Object.keys(a).includes('isActive')||(Object.keys(a).includes('isActive') && a.isActive===true)){
            return a
          }
        })
      }
    }
  })
  return dataToSave;
}
const handleSave = async () => {
  const dataToSave = prepareDataForSaving();
  emit('addUserToRow', dataToSave,props.groupId);
  isSaveButtonActive.value=false
}

const initializeData = async () => {
  setTimeout(()=>{
    usersData.value = objectsDataProps.value.map(obj=>{
      const userActions =obj.actions.map(o=>{
        return {
          id:o.id,
          name:o.name,
          key:o.key
        }
      })
      return {
        actions:userActions,
        user:{
          id:obj.id,
          name:obj.name,
        }
      }
    })
  },1000)
  setTimeout(() => {
      isReadyColumn.value = true;
  }, 1200)
}

onMounted(  async () => {
  Store.commit('accessSettings/setAccessUsersInGroups',false)
  if (objectsDataProps.value.length) {
    await initializeData();
  } else {
    isReadyColumn.value = true;
  }
});
onUnmounted(async ()=>{
  Store.commit('accessSettings/setAccessUsersInGroups',false)
  Store.commit('accessSettings/setAccessUsersInModal',false)
  await Store.dispatch('users/GET')
})
</script>

<style scoped lang="scss">
.objects__access {
  background: #f6f7fa;
  width: 100%;
  padding: 20px;
  height: 100%;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;

  &-header {
    display: flex;
    justify-content: space-between;
    padding-bottom: 16px;

    .header__left {
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 120%;
      color: #292929;
      display: flex;
      column-gap: 6px;
      align-items: center;

      &-icon {
        background: #edeff3;
        border-radius: 100%;
        height: 28px;
        width: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .header__right {
      display: flex;
      align-items: center;
      column-gap: 32px;

      &-settings {
        display: flex;
        justify-content: center;
        align-items: center;
        column-gap: 6px;
        .objects__info {
          display: flex;
          justify-content: center;
          align-items: center;
          column-gap: 6px;
          border-radius: 12px;
          background: #FFE8DB;
          color: #CA5F32;
          font-size: 8px;
          font-style: normal;
          font-weight: 400;
          line-height: 14px;
          padding: 10px 12px;
          max-width: 270px;
          height: 36px;
        }

        &-add {
          background: #bec7d3;
          color: #ffffff;
          width: 160px;
          height: 36px;
          border-radius: 8px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .active-btn {
          background: #3a7cca;
        }
      }

      &-img {
        width: 32px;
        height: 32px;
        background: #edeff3;
        border-radius: 100%;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  &-loader {
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 100px);
  }

  &-body {
    background: #ffffff;
    border-radius: 12px;

    &-header {
      padding: 12px 16px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      .group__info {
        &-name {
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 120%;
          color: #292929;
        }

        &-count {
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 120%;
          color: #a6aeb8;
        }
      }

      .group__search {
        position: relative;

        &-icon {
          position: absolute;
          top: 8px;
          left: 8px;
        }
        .input-inner{
          width: 300px;
          padding: 8px 8px 6px 33px !important;
          height: 36px !important;
          border: 1px solid #DCDFE6;
          border-radius: 10px;
          background:  #EDEFF3;
          color: #A6AEB8;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 18px;
        }
        .input-inner:focus{
          outline: none;
        }
      }

      .group__settings {
        display: flex;
        column-gap: 32px;

        &-add {
          display: flex;
          align-items: center;
          cursor: pointer;

          .add__name {
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 19px;
            color: #3a7cca;
          }
        }

        &-icons {
          display: flex;
          align-items: center;
          column-gap: 8px;

          .columns__icon {
            border-radius: 4px;
            width: 28px;
            height: 28px;
            background: #edeff3;
            padding: 4px;
          }

          .more__icon {
            border-radius: 4px;
            width: 28px;
            height: 28px;
            background: #edeff3;
            padding: 4px;

            &-radius {
              width: 20px;
              height: 20px;
              border: 1.5px solid black;
              border-radius: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
        }

        .users__items {
          position: absolute;
          top: 40px;
          right: 110px;
          z-index: 1000;
          overflow: hidden;
        }
      }
    }

    &-table {
      .table__content {
        padding-bottom: 160px;
        position: relative;
        height: calc(100vh - 190px);
        overflow-y: auto;

        table {
          width: 100%;

          thead {
            th {
              padding: 7px 12px 8px 12px;
              white-space: normal;
              font-size: 11px;
              text-align: center;
              font-weight: 400;
              line-height: 120%;
              border: 0.5px solid #cdd4dd;

              &:first-child {
                background: #ffffff;
                border-width: 0.5px;
                border-left: none;
                border-right: none;
                width: 211px;
              }
            }
          }

          tbody {
            td {
              text-align: center;
              background: #dbebff;
              border: 0.5px solid #cdd4dd;
              height: 32px;

              &:first-child {
                background: #ffffff;
                border-width: 0.5px;
                border-left: none;
                border-right: none;
              }
            }

            .active {
              background: #dbebff;
            }
          }
          .loading {
            width: 100%;
            position: absolute;
            top: 200px;
          }
        }

        .table__row {
          .table__content_item {
          }
        }
      }
    }
  }
}

::v-deep(.el-input__inner) {
  width: 300px;
  height: 36px !important;
}

::v-deep(.input-inner.el-input) {
  .el-input__inner {
    padding-left: 33px !important;
  }
}
</style>
