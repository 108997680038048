<template>
  <label :class="['checkbox', { disabled: disabled }]">
    <input
        v-model="check"
        :value="value"
        :checked="checked"
        name="checkbox"
        :disabled="disabled"
        type="checkbox"
        @change="changeValue"
    >
    <span class="checkbox__span" />
    {{ label }}
  </label>
</template>

<script setup lang="ts">
import { defineProps, defineEmits, ref } from "vue"

const props = defineProps({
  value: {
    type: [String, Boolean, Array],
    default: false
  },
  label: {
    type: String,
    default: '',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  checked: {
    type: Boolean,
    default: false,
  },
  truckBy: {
    type: String,
    default: '',
  },
  index: {
    type: [String, Number],
    default: '',
  },
  item: {
    type: [Object, String],
    default: () => {
      return {
        id: ''
      }
    },
  }
})

const emit = defineEmits(['update:modelValue', 'change'])
const check = ref(props.checked)

const changeValue = () => {
  if(!props.disabled) {
    if (typeof props.value === 'object') {
      let value = props.value

      if (check.value) {
        if (props.truckBy) {
          value.push(props.item[props.truckBy])
        } else {
          value.push(props.item)
        }
      } else {
        value=value.filter(el=>el.id!==props.item.id)
      }
      emit('input', value)
      emit('change', props.item)
    } else {
      emit('update:modelValue', !props.value)
      emit('change', !props.value)
    }
  }
}
</script>

<style scoped lang="scss">
.checkbox {
  width: max-content;
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 120%;
  color: #292929;
  cursor: pointer;
  white-space: nowrap;
  transition: 0.3s;
  gap: 8px;

  &:before {
    display: none;
  }

  &.disabled {
    cursor: default;
    color: #777B82;

    .checkbox__span {
      border-color: #828D9B;

      &:before {
       border-color: #828D9B;
      }
    }

    input:checked ~ .checkbox__span {
      background: #EDEFF3;
    }
  }
  input {
    display: none;
  }


  input:checked ~ .checkbox__span:before {
    transform: translate(-50%, -50%) rotateZ(45deg) scale(1)
  }

  &__span {
    position: relative;
    display: block;
    height: 12px;
    background: #FFFFFF;
    border: 1px solid #3A7CCA;
    border-radius: 2px;
    box-sizing: border-box;
    transition: 0.3s;
    padding: 0 5px;

    &:before {
      content: '';
      position: absolute;
      top: 40%;
      left: 50%;
      border-right: 1.5px solid #3A7CCA;
      border-bottom: 1.5px solid #3A7CCA;
      width: 5px;
      height: 7px;
      transform: translate(-50%, -50%) rotateZ(45deg) scale(0);
      transition: 0.3s;
    }
  }
}
</style>
