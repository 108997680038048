<template>
  <div :class="['tooltip', classes]">
    <div class="tooltip__activator"
         @mouseenter="isActivatorHovered = true"
         @mouseleave="isActivatorHovered = false"
    >
      <slot name="activator"></slot>
    </div>
    <div class="tooltip__body"
         :class="{ 'visible': showTooltip }"
         @mouseenter="isTooltipHovered = true"
         @mouseleave="isTooltipHovered = false"
    >
      <span class="tooltip__body-text">
        {{ text }}
      </span>
      <div v-if="type==='access'">
          <p style="color:cornflowerblue; font-size: 12px; width: max-content">
            {{ groupCountTitle }} {{ groupsNames }}
          </p>
        <p style="font-size: 12px;width: max-content">
        <a @click="goToGroupAccess" style="color:blue;text-decoration: underline;cursor:pointer">Перейти к настройкам групп</a>
        </p>
        <br>
        <p style="color:cornflowerblue; font-size: 12px;width: max-content">
          Настройка доступа осуществляется через группы
        </p>
        <p style="color:cornflowerblue; font-size: 12px;width: max-content">
            доступа и добавление объекта в соответствующую
        </p>
        <p style="color:cornflowerblue; font-size: 12px;width: max-content">
            группу.
        </p>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {defineProps, computed, ref} from "vue";

const props = defineProps({
  text: {
    type: String,
    default: '',
  },
  background: {
    type: String,
    default: "",
  },
  color: {
    type: String,
    default: "",
  },
  top: {
    type: Boolean,
    default: false,
  },
  bottom: {
    type: Boolean,
    default: false,
  },
  right: {
    type: Boolean,
    default: false,
  },
  left: {
    type: Boolean,
    default: false,
  },
  type:{
    type:String,
    default:''
  },
  groupsNames:{
    type:String,
    default:'Камазы, Спецтехника'
  }
});

const classes = computed(() => {
  return props.top ? "top" : props.bottom ? "bottom" : props.right ? "right" : props.left ? "left" : "";
});

const emit=defineEmits(['toGroupAccess'])
const isActivatorHovered = ref(false);
const isTooltipHovered = ref(false);

const showTooltip = computed(() => isActivatorHovered.value || isTooltipHovered.value);
const goToGroupAccess = () => {
  emit('toGroupAccess')
}
const groupCountTitle = computed(()=>{
  return props.groupsNames.split(',').length < 2 ? 'Объект в группе:' : 'Объект в группах:'
})
</script>

<style scoped lang="scss">
.tooltip {
  position: relative;
  width: 100%;

  &.right {
    .tooltip__body {
      left: 101%;
      top: 50%;
      transform: translateY(-50%);
      margin-left: 4px;
    }
  }

  &.left {
    .tooltip__body {
      right: 101%;
      top: 50%;
      transform: translateY(-50%);
      margin-right: 4px;
    }
  }

  &.bottom {
    .tooltip__body {
      top: 101%;
      margin-top: 4px;
      left: 50%;
      transform: translateX(50%);
    }
  }

  &.top {
    .tooltip__body {
      bottom: 101%;
      margin-bottom: 4px;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &__activator {
    cursor: pointer;
    width: 100%;

    &:hover ~ .tooltip__body {
      opacity: 1;
      visibility: visible;
    }
  }

  &__body {
    position: absolute;
    background: v-bind(background);
    box-shadow: 0 4px 6px rgba(7, 40, 82, 0.06);
    border-radius: 12px;
    padding: 10px 12px;
    box-sizing: border-box;
    transition: 0.3s;
    opacity: 0;
    visibility: hidden;
    z-index: 11;
    display: flex;
    align-items: center;
    justify-content: center;

    &-text {
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      color: v-bind(color);
      white-space: nowrap;
      width: 100%;
    }
  }
}
.visible {
  opacity: 1 !important;
  visibility: visible !important;
}
</style>
