import { render, staticRenderFns } from "./checkbox.vue?vue&type=template&id=732dc37c&scoped=true"
import script from "./checkbox.vue?vue&type=script&setup=true&lang=ts"
export * from "./checkbox.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./checkbox.vue?vue&type=style&index=0&id=732dc37c&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "732dc37c",
  null
  
)

export default component.exports