<template>
  <div class="samples">
    <div
        v-for="(item, key) in itemsActions"
        :key="key"
        class="samples__item"
    >
      <div class="samples__blocks">
        <div class="samples__blocks-title">{{ item.title }}</div>
        <div v-if="!loader" class="samples__blocks-items">
          <UiTooltip
              v-for="(el, idx) in item.items"
              :key="idx"
              :text="'Вы не можете активировать данный доступ'"
              background="#DBEBFF"
              color="#20579A"
              buttom
              v-if="disabled===true"
          >
            <template #activator>
              <UiCheckbox
                  :label="el.name"
                  :checked="el.checked"
                  :item="el"
                  v-model="checkedItems"
                  @change="changeSamples(el)"
                  :disabled="disabled"
              />
            </template>
          </UiTooltip>
          <UiCheckbox
              v-if="disabled===false"
              v-for="(el, idx) in item.items"
              :key="idx"
              :label="el.name"
              :checked="el.checked"
              :item="el"
              v-model="checkedItems"
              @change="changeSamples(el)"
              :disabled="disabled"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {ref, defineProps, watch, defineEmits, computed} from 'vue'
import { useStore } from '@/store/store.js'
import UiCheckbox from '@/components/companyControlsCenter/components/AccessSettings/ui/checkbox.vue'
import UiTooltip from './ui/tooltip.vue'

const Store = useStore()
const checkedItems = ref([])
const loader = ref(false)

const props = defineProps({
  actions: {
    type: Array, Object,
    default: () => []
  },
  disabled: {
    type: Boolean,
    default: false
  },
})
const emits = defineEmits(['checked', 'checkboxValueIsChanged'])

const itemsActions = computed(() => {
  const actions = Store.state.accessSettings.checkedSamples
  checkedItems.value = actions

  if (!actions) return []
  return props.actions.map((item) => {
    item.items = item.items.map((simpleItem) => {
      simpleItem.checked = false
      actions.forEach(checkedItem => {
        if (simpleItem.id === checkedItem.id) {
          simpleItem.checked = true
        }
      })
      return simpleItem
    })
    return item
  })
})

const changeSamples = (item) => {
  item.checked = !item.checked
  Store.state.accessSettings.checkedSamples = checkedItems.value
  emits('checkboxValueIsChanged', checkedItems.value)
}

watch(
    () => Store.state.accessSettings.loader,
    () => {
      loader.value = true

      setTimeout(() => {
        loader.value = false
      }, 0)
    },
    {
      deep: true
    }
);

watch(
    () => checkedItems.value,
    () => {
      checkboxValueToJson.value = JSON.stringify(checkedItems.value)
    }
)
const checkboxValueToJson = ref('')

</script>

<style scoped lang="scss">
.samples {
  padding: 18px 0;
  display: flex;
  justify-content: space-between;
  overflow-y: auto;
  height: calc(100vh - 200px);

  &__blocks {
    &-title {
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
      text-transform: uppercase;
      color: #828d9b;
      margin-bottom: 16px;
    }

    &-items {
      display: flex;
      flex-direction: column;
      row-gap: 20px;
      max-width: 355px;
    }
  }
}
</style>
