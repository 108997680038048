<template>
  <div class="">
    <label v-for="(item, idx) in data" :key="idx" class="essence" @click="$emit('selectedGroup', item)">
      <div class="essence__user">
        <div class="essence__user-info">
          <div :class="['essence__user-info-name', { 'active': item.name === checkedItem }]">{{ item.name }}</div>
          <div class="essence__user-info-count">
            {{ $t('notifications.many.object') }}: {{ availableObjectsCount[idx] }}
          </div>
        </div>
        <div class="essence__user-img">
          <UiIcon
            height="8px"
            value="arrow"
            width="5px"
          />
        </div>
      </div>
      <input v-model="checkedItem" :value="item.name" name="name" type="radio">
    </label>
  </div>
</template>

<script setup>
import { ref, defineProps } from 'vue'
import UiIcon from './ui/icon.vue'
import { useStore } from '@/store/store.js'

const Store = useStore()

const props = defineProps({
  data: Array,
  default: () => []
})
const checkedItem = ref('')

const availableGroupsIds = props.data.map(user => user.groups_ids)
const unitsGroups = Store.state.units.unitsGroups

const availableObjectsCount = availableGroupsIds.map(groupsIds => {
  if (groupsIds.length) {
    let result = []
    groupsIds.forEach(id => {
      const objectsCount = unitsGroups.filter(unitGroup => unitGroup.id === id)[0]?.units.length
      result.push(objectsCount)
    })
    result = result.reduce((acc, item) => {
      acc += item
      return acc
    })
    return result
  } else {
    return 0
  }
})
</script>

<style lang="scss" scoped>
.essence {
  &__user {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 7px 18px 9px 20px;
    border-bottom: 1px solid #F6F7FA;

    &:hover {
      background: #EDEFF3;
    }

    .transform {
      transform: rotate(90deg);
    }

    &-info {

      &-name {
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 120%;
        color: #292929;
      }

      .active {
        color: #5C9DEB;
      }

      &-count {
        font-size: 9px;
        font-style: normal;
        font-weight: 600;
        line-height: 80%;
        letter-spacing: 0.5px;
        text-transform: uppercase;
        color: #A6AEB8;
      }
    }

    &-img {
      cursor: pointer;
    }
  }
}

input {
  display: none;
}
</style>
