<template>
  <div class="create__new-role">
    <div v-if="createRole" class="create__role">
      <div class="create__role-top" @click="openClose">
        <div class="create__img">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="8" cy="8" r="7.5" stroke="#3A7CCA"/>
            <path d="M7 5C7 4.44772 7.44772 4 8 4C8.55228 4 9 4.44772 9 5V11C9 11.5523 8.55228 12 8 12C7.44772 12 7 11.5523 7 11V5Z" fill="#3A7CCA"/>
            <path d="M11 7C11.5523 7 12 7.44772 12 8C12 8.55228 11.5523 9 11 9L5 9C4.44772 9 4 8.55228 4 8C4 7.44772 4.44772 7 5 7L11 7Z" fill="#3A7CCA"/>
          </svg>
        </div>
        <span class="create__title">
          {{ $t('access.settings.create.role') }}
        </span>
      </div>
    </div>
    <div v-else class="new__role">
      <el-input
          :placeholder="$t('access.settings.create.role.title')"
          v-model="roleName"
          @focus="roleName = 'Новая роль'"
      />
      <div class="new__role-save" @click="addRole">
        <svg width="10" height="7" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1 3.8L4.75 8L11 1" stroke="white" stroke-width="2" stroke-linecap="round"/>
        </svg>
      </div>
      <div class="new__role-close" @click="openClose">
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="1.75684" y="0.34375" width="14" height="2" rx="1" transform="rotate(45 1.75684 0.34375)" fill="#C93131"/>
          <rect x="11.6562" y="1.75781" width="14" height="2" rx="1" transform="rotate(135 11.6562 1.75781)" fill="#C93131"/>
        </svg>
      </div>
    </div>
    <div class="required__text">
      <span v-if="showErrorText && !roleName">
        {{ $t('access.settings.create.role.specify') }}
      </span>
    </div>
  </div>
</template>

<script setup>
import { ref, defineEmits, watch, defineProps } from 'vue'
import { useStore } from '@/store/store.js'

const Store = useStore()
const createRole = ref(true)
const showErrorText = ref(false)
const roleName = ref('')
const showLoading = ref(false)

const emits = defineEmits(['loadingRoles'])
const props = defineProps({
  roleNames: {
    type: Array,
    default: () => []
  }
})
const openClose = () => {
  createRole.value = !createRole.value
  showErrorText.value = false
}

const addRole = () => {
  if(roleName.value) {
    const data = {
      value: roleName.value,
      actions: []
      // actions: Store.state.accessSettings.checkedSamples
    }
    showLoading.value = true
    setTimeout(() => {
      showLoading.value = false
      createRole.value = true
      roleName.value = ''
      Store.dispatch('accessSettings/createNewRole', data)

    }, 300)
    emits('loadingRoles', showLoading.value)

    showErrorText.value = false
  } else {
    showErrorText.value = true
  }
}

</script>

<style scoped lang="scss">
.create__new-role {
  border-bottom: 1px solid #F6F7FA;
}
.create__role {
  display: flex;
  justify-content: left;
  padding: 10px 20px;

  &-top {
    cursor: pointer;
    display: flex;
    align-items: flex-end;
    column-gap: 4px;


    .create__img {
      padding-top: 3px;
    }

    .create__title {
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 19px;
      color: #3A7CCA;
    }
  }
}

.required__text {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  color: #C93131;
  padding: 2px 0 6px 28px;
}
.new__role {
  display: flex;
  column-gap: 8px;
  align-items: center;
  padding: 10px 20px;

  &-save {
    height: 20px;
    width: 20px;
    background: #5C9DEB;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px 5px;
    cursor: pointer;
  }

  &-close {
    height: 20px;
    width: 20px;
    background: #EDEFF3;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px 4px;
    cursor: pointer;

  }
}

:deep(.input) {
  border-radius: 6px !important;
  padding: 4px 12px;

  .input__inner {
    line-height: 80%;
    font-size: 19px;

    input {
      padding: 0;

      ::placeholder {
        font-size: 9px;
      }
    }
  }
}

:deep(.el-input) {
  input {
    width: 225px !important;
    border-radius: 6px !important;
    height: 24px !important;
    padding: 4px 8px !important;
  }
}
</style>
