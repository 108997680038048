<template>
  <div>
    <label class="groups" v-for="item in data" :key="item.id" @change="activeItem(item)">
      <div class="groups__group">
        <div class="groups__group-info">
          <div class="groups__group-info-img">
            <img v-if="item.photos[1]" :src="'/' + 'api_v1' + item.photos[1].url" alt="">
            <img v-else src="/img/Avatar.c6df6ded.svg" alt="">
          </div>
          <div class="groups__group-info-count">
            <div :class="['groups__group-info-name', { active: item.name === checkedItem }]">{{ item.name }}</div>
            <div class="groups__count">{{item.groups_count}}</div>
          </div>
        </div>
        <div class="groups__group-img">
          <UiIcon
              class="cursor-pointer"
              value="arrow"
              width="5px"
              height="8px"
              @click="$emit('openObject', item)"
              :class="{transform: item.showDeleteGroup}"
          />
        </div>
      </div>
      <input type="radio" name="name" :value="item.name" v-model="checkedItem">
    </label>
  </div>
</template>

<script setup>
import {defineProps, defineEmits, ref, onMounted} from 'vue'
import UiIcon from "./ui/icon.vue"

const props = defineProps({
  data: {
    type: Array,
    default: () => []
  }
})
const emit = defineEmits(['objectIsActive'])

const checkedItem = ref('')


const changeUsersGroup = (item) => {
  props.data.forEach(val => {
    if(val.id === item.id) {
      val.isActive = true
      return val
    }
  })

}

const activeItem = (val) => {
  emit('userIsActive', val)
}

onMounted(()=>{
  checkedItem.value = props.data[0].name
  activeItem(props.data[0]);
})
</script>

<style scoped lang="scss">
.groups {

  &__group {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 7px 18px 9px 20px;
    border-bottom: 1px solid #F6F7FA;

    &:hover {
      background: #EDEFF3;
    }
    .transform {
      transform: rotate(90deg);
    }


   &-info {
     display: flex;
     column-gap: 6px;

     &-img {
       width: 28px;
       height: 28px;
       border-radius: 100%;
       overflow: hidden;
       img{
         width: 100%;
         height: 100%;
       }
     }

     &-count {
       display: flex;
       flex-direction: column;

       &-name {
         font-size: 14px;
         font-style: normal;
         font-weight: 600;
         line-height: 120%;
         color: #292929;
       }
       .active {
         color: #3A7CCA;
       }
       .groups__count {
         font-size: 9px;
         font-style: normal;
         font-weight: 600;
         line-height: 80%;
         letter-spacing: 0.5px;
         text-transform: uppercase;
         color: #A6AEB8;
       }
     }

   }
  }
}

input {
  display: none;
}
</style>
