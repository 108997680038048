<template>
  <div :class="['users', addUsersType === 'objects' ? 'object__style' : 'user__style']"
       @click.stop="onClick"
  >
    <div class="users__search">
      <input
          class="users__search-input"
          :placeholder="$t('search')"
          v-model="searchUser"
      />
    </div>
    <div class="users__block">
      <div class="users__block-item" :class="{'users__block-none': user.isActive}" v-for="user in usersGroup" :key="user.id">
        <div class="users__block-item-name">{{ user.name }}</div>
        <button
            @click="addUser(user,buttonText(user))"
            :class="['button',{'disabled':buttonText(user)=== $t('access.settings.create.btn')},{'users__block-item__btn': activeClass}]"
        >
          {{ buttonText(user) }}
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps, computed, defineEmits, ref, onMounted } from 'vue'
import { useStore } from '@/store/store.js'
import { useI18n } from '@/hooks/useI18n'

const Store = useStore()
const $t = useI18n()

const props = defineProps({
  included:{
    type: Array,
    default: () => []
  },
  users: {
    type: Array,
    default: () => []
  },
  buttonName: {
    type: String,
    default: ''
  },
  addUsersType: {
    type: String,
    default: ''
  }
})
const onClick = ()=>{
  if(props.addUsersType === 'objects'){
    Store.commit('accessSettings/setAccessUsersInGroups',false)
  }else{
    Store.commit('accessSettings/setAccessUsersInModal',true)
    Store.commit('accessSettings/setAccessUsersInGroups',true)
  }
}
const searchUser = ref('')
const usersGroup = computed(() => {
  if (searchUser.value) {
    return props.users.filter((item) => {
      return item && item.name
          ? item.name
              .toLowerCase()
              .includes(searchUser.value.toLowerCase())
          : null
    })
  }

  return props.users
})
const emit = defineEmits(['deleteItem', 'addUser'])
const addUser = (e,text) => {
  if (props.addUsersType === 'objects') {
    emit('deleteItem', e.id)
  }else if(props.addUsersType === 'users') {
    if (text !== $t('access.settings.create.btn')) {
      emit('addUser', e)
      e.isActive = true
    }
  }

  // props.users.forEach((user) => {
  //   if(user.id === e.id) {
  //     emit('addUser', user)
  //     user.isActive = true
  //   }
  // })
}

const buttonText = computed(() => {
  return (user) => {
    let active=false
    props.included.forEach(el=>{
      if (el.id===user.id) {
        active = true
      }
    })
    if (props.addUsersType === 'users' ){
      return active ? props.buttonName : $t('access.settings.create.btn.add')
    }
  }
})
const activeClass = computed(() => {
  return (user) => {
    return { active: user.isActive && props.addUsersType === 'users', red: user.isActive && props.addUsersType === 'objects' }
  }
})

onMounted(async ()=>{
  await Store.dispatch('users/GET')
})
</script>

<style scoped lang="scss">
.users {
  width: 266px;
  height: max-content;
  max-height: 460px;
  background: #FFFFFF;
  border-radius: 12px;
  border: 1px solid #EDEFF3;

  &__search {
    padding: 8px;
    border-bottom: 1px solid #EDEFF3;

    &-input {
      width: 250px;
      height: 36px;
      border-radius: 10px;
      color: #292929;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
      background: #EDEFF3;
      padding: 8px !important;
      &:focus{
        outline: none;
      }
    }

  }

  &__block {
    max-height: 400px;
    overflow-y: scroll;

    &-item {
      padding: 8px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #EDEFF3;

      &:hover {
        background: #EDEFF3;
      }

      &-name {
        max-width: 200px;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
        color: #171717;
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .button {
        border: none;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
        color: #A6AEB8;
        width: max-content;
        height: max-content;
        padding: 0;
        background: transparent;
        &:hover {
          color: #3A7CCA;
        }
     }
      &__btn {
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
        color: #A6AEB8;
        border: none;
        &:focus{
          outline: none;
        }
        &:active{
          outline: none;
        }
      }
      .disabled{
        border:none;
        pointer-events: none;
        color: rgba(166, 174, 184, .5);
      }

    }

    &-none {
      pointer-events: none;
    }
  }
}
//
//::v-deep(.button) {
//  font-size: 12px;
//  font-style: normal;
//  font-weight: 400;
//  line-height: 140%;
//  background: inherit;
//  color: #A6AEB8;
//  width: max-content;
//  height: max-content;
//}
.active {
  color: #30B474;
}

//.user__style {
//  .users__block-item ::v-deep(.el-button):hover {
//    &:hover {
//      color: #3A7CCA;
//    }
//  }
//}


.object__style {
  .users__block-item ::v-deep(.el-button):hover {
    &:hover {
      color: red;
    }
  }
}

::v-deep(.el-input__inner) {
  width: 250px !important;
  height: 36px !important;
}

//::v-deep(.el-button) {
//  padding: 0;
//  background: transparent;
//}
</style>
