<template>
  <div v-if="modelValue" :class="[ 'dialog__backdrop', classes ]">
    <transition name="slide-fade" appear>
      <div class="dialog">
        <slot />
      </div>
    </transition>
  </div>
</template>

<script setup lang="ts">
import {defineProps, computed, onMounted, defineEmits} from "vue";

const props = defineProps({
  modelValue: [String, Number, Boolean],
  width: {
    type: String,
    default: 'max-content',
  },
  start: {
    type:Boolean,
    default: false
  },
  end: {
    type:Boolean,
    default: false
  }
})

const classes = computed(() => {
  return props.start ? 'start' : props.end ? 'end' : ''
})
const emit = defineEmits(['update:modelValue'])

onMounted(() => {
  window.addEventListener('keyup', function(event) {
    if (event.keyCode === 27) {
      emit('update:modelValue', false)
    }
  });
})
</script>

<style scoped lang="scss">
.dialog {
  width: v-bind(width);
  position: relative;
  transition: 0.3s;
  box-sizing: border-box;
  max-height: 90vh;
  height: 100%;
  overflow-y: auto;

  &__backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1500;
    background-color: rgba(0, 0, 0, 0.2);
  }

}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateY(20px);
  opacity: 0;
}

.start {
  justify-content: flex-start;

  .dialog {
    max-height: 100%;
  }

}

.end {
  justify-content: flex-end;

  .dialog {
    max-height: 100%;
  }
}

.dialog::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.dialog {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
</style>
