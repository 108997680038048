<template>
  <div class="access">
    <div class="access-settings">
      <div class="access-settings__header">
        <div class="access-settings__header-title">
          {{ $t('access.settings.title') }}
        </div>
        <div class="access-settings__header-body">
          <el-select
              class="mr-4"
              width="279"
              heigth="39"
              v-model="roleName"
              @change="changeRole"
          >
            <el-option
                v-for="(item, idx) in usersAndGroupSettings"
                :key="idx"
                :value="item.name"
            />
          </el-select>
          <input
              class="input-inner"
              :placeholder="$t('search')"
              v-model="searchItem"
              @input="search"
          />
          <UiIcon value="skif_search" height="20px" width="20px" class="search__icon"/>
        </div>
      </div>
      <div
          v-if="showLoading"
          :class="[ 'access-settings__body', { 'access-settings__body_2': roleName === 'Группы доступа' || roleName === 'Пользователи (Группы)'}]"
      >
        <Roles
            v-if="roleName === 'Роли'"
            v-for="role in allRoles"
            :key="role.id"
            :item="role"
            @click.native="changeRoleName(role)"
            @deleteItem="deleteRole"
        />
        <div v-if="Store.state.accessSettings.isLoading === true">
          <UiLoader/>
        </div>
        <GroupAccess
            v-if="roleName === 'Группы доступа' && Store.state.accessSettings.isLoading === false"
            :data="unitsGroups"
            :current="currentObject"
            @objectIsActive="onGroupChange"
            @openModalDelete="showModalDelete"
            @deleteItem="deleteItem"
            @addUser="addUser"
        />
        <UsersGroupsOrObjects
            v-if="roleName === 'Пользователи (Группы)'"
            :data="allUsers"
            @userIsActive="selectedUser"
        />
        <UsersEssence
            v-if="roleName === 'Пользователи (Сущность)'"
            :data="allUsers"
            @selectedGroup="changeActiveGroup"
        />
      </div>
      <div  class="loader" v-else>
        <UiLoader/>
      </div>
      <div class="access__settings-add">
        <AddNewRole
            v-if="roleName === 'Роли'"
            :roleNames="allRoles"
            @loadingRoles="updateRoles($event)"
        />
      </div>
    </div>
    <div class="access-all">
      <AllAccess
          v-if="roleName === 'Роли'"
          :item="currentRole"
          :disabled="disabled"
      />

      <AccessUsersInGroup
          :key="rerenderKey"
          v-if="roleName === 'Группы доступа'"
          :objectInfo="currentObject"
          :objects="objectsInfo"
          :groupId="selectedGroupId"
          @addUserToRow="addUserToRow"
          @updateRerenderKey="updateRerenderKey"
          @changeUserRole="changeUserRole"
      />
      <UserAccessByGroup
          v-if="roleName === 'Пользователи (Группы)'"
          :userInfo="userInfo"
          :userId="selectedGroupId"
          :addUsersToGroup="unitsGroup"
          :users="groupsInfo"
          @refreshCount="Store.dispatch('users/GET')"
      />
      <UserAccessToObjects
          :objects="unitsGroup"
          :userInfo="currentUser"
          v-if="roleName === 'Пользователи (Сущность)'"
          @changeGroup="roleName='Группы доступа'"
      />
    </div>
    <ModalDelete
        v-model="openModal"
        v-if="openModal"
        :items="allRoles"
        :deleteUser="deleteDataItem"
        @closeModal="openModal = $event"
        @deleteItem="removeRole"
    />
  </div>
</template>

<script setup>
import { computed, ref, watch, provide,onUnmounted, onMounted } from 'vue'
import { useStore } from '@/store/store.js'
import Roles from '@/components/companyControlsCenter/components/AccessSettings/roles.vue'
import AllAccess from '@/components/companyControlsCenter/components/AccessSettings/AllAccess.vue'
import AddNewRole from "@/components/companyControlsCenter/components/AccessSettings/addNewRole.vue"
import UiLoader from '@/components/unitsNew/components/modalSensors/components/loader.vue'
import ModalDelete from "@/components/companyControlsCenter/components/AccessSettings/modalDelete.vue"
import GroupAccess from "@/components/companyControlsCenter/components/AccessSettings/groupAccess.vue"
import AccessUsersInGroup from "@/components/companyControlsCenter/components/AccessSettings/accessUsersInGroup.vue"
import UsersGroupsOrObjects from "@/components/companyControlsCenter/components/AccessSettings/usersGroupsOrObjects.vue"
import UserAccessByGroup from "@/components/companyControlsCenter/components/AccessSettings/userAccessByGroup.vue"
import UsersEssence from "@/components/companyControlsCenter/components/AccessSettings/usersEssence.vue"
import UserAccessToObjects from "@/components/companyControlsCenter/components/AccessSettings/userAccessToObjects.vue"
import UiIcon from "./ui/icon.vue"
import _,{debounce} from "lodash";

const Store = useStore()
const currentRole = ref({})
const searchItem = ref('')
const showLoading = ref(true)
const deleteDataItem = ref({})
const roleName = ref('Роли')
const groupsInfo = ref([])
const openModal = ref(false)
const active = ref(false)
const objectsInfo = ref([])
const userInfo = ref({})
const currentUser = ref({})

const usersAndGroupSettings = ref([
  { name: 'Роли', id: 1 },
  { name: 'Группы доступа', id: 2 },
  { name: 'Пользователи (Группы)', id: 3 },
  { name: 'Пользователи (Сущность)', id: 4 }
])
const rerenderKey = ref(0);

const openRoles = (role) => {
  const item = allRoles.value.filter(r=>r.id===role?.id)[0]
  changeRoleName(item)
  roleName.value = 'Роли'
}

provide('changePage', { roleName, openRoles })

const changeUserRole=async (item,row)=>{
  await Store.dispatch('accessSettings/changeUserRole', { id: row?.id, role: {
      id: item?.id,
      value: item.value,
      key: item.key
    }
  }).then(() => {
    updateRerenderKey(currentObject.value?.id)
  })
}
const addUserToRow = async (dataToSave,id)=>{
  await Store.dispatch('accessSettings/saveRoleActions', {form: dataToSave, id: id})
        .then(async (res) => {
          objectsInfo.value=res.map(r=>{
            return{
              actions:r.actions,
              available_actions_keys:r.available_actions_keys,
              ...r.user,
            }
          })
          Store.state.accessSettings.isLoading = false;
        })
}

const unitsGroup = computed(() => {
  return Store.getters['units/unitsGroup'].map(val => {
    val.isActive = false
    val.showPopup = false
    return val
  })
})

const unitsGroups = ref([])
unitsGroups.value = unitsGroup.value
const currentObject = ref(unitsGroup.value[0])
const selectedGroupId = ref(unitsGroup.value[0] ? unitsGroup.value[0]?.id : null)


const allRoles = computed(() => {
  return Store.getters['accessSettings/getAllRoles'].map((val) => {
    val = Object.assign(
        {...val},
        {isActive: currentRole.value && val.id === currentRole.value?.id }
    )
    return val
  })
})

const allUsers = computed(() => {
  return Store.getters['users/getAllUsers'].map(val => {
    val.isActive = false
    val.showPopup = false
    return val
  }).filter(val=>val.company_user)
})
const disabled = ref(false)

const changeRoleName = (item) => {
  if( item.value === "Без доступа" || item.value === "Наблюдатель" || item.value === "Читатель"
      || item.value === "Оператор" || item.value === "Редактор" || item.value === "Администратор"
      || item.value === "Супервизор" || item.value === "Редактор тарифа" || item.value === "Контроллер"){
    disabled.value = true
  } else {
    disabled.value = false
  }
  Store.state.accessSettings.getRole = ''
  Store.state.accessSettings.checkedSamples = item.actions
  Store.state.accessSettings.selectedRole = JSON.parse(JSON.stringify(item))
  Store.state.accessSettings.loader = !Store.state.accessSettings.loader
  currentRole.value = Object.assign({}, item)
  currentRole.value.isActive = true
}

const updateRoles  = (e) => {
  showLoading.value = !e
  setTimeout(() => {
    showLoading.value = e
  }, 500)
}

const updateRerenderKey = (valId) => {
  if(valId) {
    selectedGroupId.value = valId

    objectsInfo.value = allUsers.value.filter(item => {
      if(item.groups_ids.includes(valId)) {
        return item
      }
    })
    Store.dispatch('accessSettings/getGroupsByObject', valId).then(res => {
      objectsInfo.value=res.map(r=>{
        return{
          actions:r.actions,
          available_actions_keys:r.available_actions_keys,
          ...r.user,
        }
      })
    })
  }
  rerenderKey.value++;
}

const users = Store.getters['users/getAllUsers'].map(val => {
  val.isActive = false
  val.showPopup = false
  return val
}).filter(val=>val.company_user)

const search = async () => {
  if(roleName.value === 'Роли') {
    await Store.dispatch('accessSettings/getAllRoles', searchItem.value)
  }
  if(roleName.value === 'Группы доступа') {

    unitsGroups.value = unitsGroup.value.filter(item => {
      return item ? item.name.toLowerCase().includes(searchItem.value.toLowerCase()) : null
    })
  }
  if(roleName.value === 'Пользователи (Группы)') {
    let final = users.filter(item => {
      return item ? item.name.toLowerCase().includes(searchItem.value.toLowerCase()) : null
    })
    Store.commit('users/SET_USERS', final)
  }
}


const deleteRole = (e) => {
  openModal.value = true
  deleteDataItem.value = e
}

const removeRole = (e) => {
  if(deleteDataItem.value) {
    Store.dispatch('accessSettings/deleteRole', {roleId: deleteDataItem.value?.id, replaceRoleId: e?.id})
    currentRole.value = {}
  }
}

const onGroupChange=_.debounce(async function(event = null, val) {
  await selectedObject(event = null, val)
}, 300, {
  loading: true,
  throttle: false,
  maxWait: 500,
})

const selectedObject = async (event = null, val) => {
  if (event) {
    event.stopPropagation();
  }
  currentObject.value = val
  selectedGroupId.value = val?.id

  objectsInfo.value = allUsers.value.filter(item => {
    if(item.groups_ids.includes(val?.id)) {
      return item
    }
  })

  await Store.dispatch('accessSettings/getGroupsByObject', val?.id).then(res => {
    objectsInfo.value=res.map(r=>{
      return{
        actions:r.actions,
        available_actions_keys:r.available_actions_keys,
        ...r.user,
      }
    })
    rerenderKey.value++;
  })

  unitsGroups.value = unitsGroups.value.map(item => {
    if(item && item?.id === val?.id) {
      item.isActive = true
    } else {
      item.isActive = false
    }
    return item
  })
}

const deleteItem = async (data) => {
  await Store.dispatch('accessSettings/deleteUnit', {id: data?.id})
  unitsGroups.value= await Store.dispatch('units/GET_GROUP_UNITS')
  unitsGroups.value= await Store.dispatch('units/GET_GROUP_UNITS')
}

const showModalDelete = (e,item) => {
  e.stopPropagation()
  unitsGroups.value=unitsGroups.value.map(val => {
    if(val?.id === item?.id){
      val.showPopup= !val.showPopup
    }else{
      val.showPopup= false
    }
    return val
  })
}

const addUser = (user) =>{
  console.log(user,'user')
}

const selectedUser = (val) => {
  userInfo.value = val
  selectedGroupId.value = val?.id;
  Store.dispatch('users/WEBSOCKET_CHANGE', {id: val?.id})
}


if(allUsers.value[0]?.id) {
  Store.dispatch('users/WEBSOCKET_CHANGE', { id: allUsers.value[0]?.id })
} else {
  console.warn('users/WEBSOCKET_CHANGE, but do not user id')
}

const changeActiveGroup = _.debounce(async function(item) {
  await activeGroup(item)
}, 1000, {
  loading: true,
  throttle: false,
  maxWait: 1200,
})

const activeGroup = (item) => {
  Store.dispatch('accessSettings/getUsersInfoById', item?.id).then(res => {
    currentUser.value = res[0]
  })
}


watch(
    () => roleName.value === 'Группы доступа',
    () => {

      currentObject.value = unitsGroup.value[0]
      selectedGroupId.value = unitsGroup.value[0]?.id

      objectsInfo.value = allUsers.value.filter(item => {
        if(item.groups_ids.includes(unitsGroup.value[0]?.id)) {
          return item
        }
      })
      Store.dispatch('accessSettings/getGroupsByObject', unitsGroup.value[0]?.id).then(res => {
        res.forEach(responseItem => {
          // Находим соответствующего пользователя в objectsInfo.value
          const userIndex = objectsInfo.value.findIndex(user => user?.id === responseItem.user?.id);

          if (userIndex !== -1) {
            // Добавляем поля actions и available_actions_keys к соответствующему пользователю
            objectsInfo.value[userIndex].role = responseItem.user.role;
            objectsInfo.value[userIndex].actions = responseItem.actions;
            objectsInfo.value[userIndex].available_actions_keys = responseItem.available_actions_keys;
          }
        });
      })
    }
)

Store.dispatch('units/GET_GROUP_UNITS')
Store.dispatch('accessSettings/getAllRoles').then((res) => {
  if (res && res.length) {
    setTimeout(() => {
      changeRoleName(res[0])
    }, 300)
  }
})

const changeRole = () => {
  if(roleName.value === 'Роли') {
    console.log('Роли');
  }
  if(roleName.value === 'Группы доступа') {
    console.log('Группы доступа');
  }
  if (roleName.value === 'Пользователи (Группы)') {
    console.log('Пользователи (Группы)');
  }
  if (roleName.value === 'Пользователи (Сущность)') {
    console.log('Пользователи (Сущность)');
    Store.dispatch('accessSettings/getUsersInfoById', allUsers.value[0]?.id).then(res => {
      currentUser.value = res[0]
    })

  }
}
onUnmounted(async ()=>{
  await Store.dispatch('units/GET_GROUP_UNITS')
})
</script>

<style scoped lang="scss">
.access {
  animation-timing-function: ease-out;
  position: fixed;
  width: 100%;
  left: 0;
  right: 0;
  top: 40px;
  bottom: 0;
  z-index: 1111;
  border-radius: 20px 20px 0 0;
  background: #ffffff;
  display: flex;

  &-settings {

    max-width: 335px;
    &__header {
      width: max-content;
      padding: 20px;
      display: flex;
      flex-direction: column;
      row-gap: 12px;
      border-bottom: 1px solid #f6f7fa;

      &-title {
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%;
        color: #292929;
      }

      &-body {
        position: relative;
        display: flex;
        flex-direction: column;
        row-gap: 16px;

        ::v-deep(.el-select) {
          .el-input__inner{
            color: #292929;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 18px;
            border-radius: 10px;
            background: #EDEFF3;
            width: 279px;
            height: 39px !important;
            padding: 8px !important;
          }
          .el-input__inner::placeholder {
            color: rgba(58, 124, 202, 1);
            font-size: 13px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
          }
          .el-input__suffix {
            top: 2px;
          }
        }

        .search__icon {
          position: absolute;
          top: 64px;
          left: 8px;
        }
        .input-inner{
          width: 279px;
          padding: 8px 8px 6px 33px !important;
          height: 36px !important;
          border: 1px solid #DCDFE6;
          border-radius: 10px;
          background:  #EDEFF3;
          color: #A6AEB8;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 18px;
        }
        .input-inner:focus{
          outline: none;
        }
      }
    }

    &__body {
      height: calc(100vh - 300px);
      overflow-y: auto;
    }

    &__body_2 {
      height: calc(100vh - 250px);
    }

    .loader {
      width: 100px;
      margin: 100px auto;
    }
  }
  &-all {
    width: 100%;
  }
}

::v-deep(.el-input__inner) {
  color: #292929;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  border-radius: 10px;
  background: #EDEFF3;
  width: 279px;
  height: 39px !important;
  padding: 8px !important;
}
::v-deep(.el-input .el-input--suffix .is-focus){
  .el-input__inner{
    border-radius: 10px;
  }
}

::v-deep(.el-icon-arrow-up:before) {
  color: #292929;
}

::v-deep(.el-select) {
  input {
    padding-top: 0;
  }
}

::v-deep(.input-inner.el-input) {
  .el-input__inner {
    padding-left: 33px !important;
  }
}

</style>
